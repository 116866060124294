/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-tell-someone-they-need-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-tell-someone-they-need-hearing-aids",
    "aria-label": "how to tell someone they need hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to tell someone they need hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss, just like any health issue, is personal and can be hard to accept. So, if you think someone may need ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ", there are right and wrong ways to go about telling them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When someone fails to respond or responds incorrectly when asked a question, or frequently asks others to repeat themselves, they’ll often get the following comments:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Are you deaf or something?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Are you not listening to me?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "You need to get your ears checked."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "I think you need hearing aids."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These types of comments, while usually not intended to be hurtful, can lead to hurt feelings, anger, and isolation — making it less likely for someone to feel comfortable seeking care. On average, people wait seven years before they’ll talk to a doctor about hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are positive, supportive ways to encourage your loved one to consider hearing aids sooner than later."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-talk-to-someone-about-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-talk-to-someone-about-hearing-loss",
    "aria-label": "how to talk to someone about hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to talk to someone about hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Find a private area")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re ready to talk to your loved one about hearing aids, make sure you find a quiet, private space when others aren’t around. Don’t approach the subject in public where it could cause embarrassment or an argument."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Talk WITH them, not at them")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Be gentle and kind, and don’t lecture your loved one. Instead, have a two-way conversation. Ask them questions about possible hearing loss, what they think about their hearing, and if they’ve considered a hearing test. Don’t just tell them what you think they should do."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Gently suggest a hearing test")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Suggesting a hearing test  is a great first step. Wait for the right time to mention it, such as when they are struggling to hear the TV or blame others for things like mumbling or speaking too softly. Gently ask if they’ve ever considered getting their hearing tested, and suggest that it can help to confirm or rule out hearing issues. Free online hearing tests like ", React.createElement(_components.a, {
    href: "https://www.hear.com/d/full_page_questionnaire_test/",
    className: "c-md-a"
  }, "this one"), " are also available, which may be less threatening since they can take it from the comfort of home."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Ask other hearing aid users for help")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you know someone who wears hearing aids, they can be a great resource and support for your loved one. A person with hearing loss may seek care more quickly if they have someone to give them first-hand suggestions, encouragement, and support."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Offer your help and support")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While someone’s hearing loss can be hard and frustrating to you as well, don’t demand they seek care. Let them come to you when they’re ready. Simply tell them you’re there for them when they’re ready and you’ll support them every step of the way."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "reach-out-to-our-hearing-specialists",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reach-out-to-our-hearing-specialists",
    "aria-label": "reach out to our hearing specialists permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reach out to our hearing specialists"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Is your loved one ready to move forward with a hearing test and a no-cost 45-day trial of ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "modern hearing aids"), "? One of our expert hearing care specialists can guide you along the way and connect you with one of our hearing professionals in your own neighborhood. We are standing by to help your loved one hear the world clearly again."), "\n", React.createElement(LandingPageCta, {
    copy: "Get Help from a Hearing Professional",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
